import { uuidV4 } from '@animoto/utils/user';

import videojs from 'video.js';
import qualitySelector from '@silvermine/videojs-quality-selector';
import ControlsSpacer from './ControlsSpacer';
import PlayerCTA from './PlayerCTA';
import convertOptions from './utils/convertOptions';
import sortSources from './utils/sortSources';
import appendTypeToSources from './utils/appendTypeToSources';

import '../node_modules/video.js/dist/video-js.min.css';
import './player.css';

qualitySelector(videojs);

videojs.registerComponent('ControlsSpacer', ControlsSpacer);

const defaultVideoOptions = {
  autoplay   : 'any',
  controlBar : {
    children : [
      'playToggle',
      'progressControl',
      'volumePanel',
      'currentTimeDisplay',
      'timeDivider',
      'durationDisplay',
      'controlsSpacer',
      'qualitySelector',
      'fullscreenToggle'
    ],
    volumePanel : { inline : false }
  },
  controls : true,
  html5    : {
    vhs : {
      overrideNative : true
    }
  },
  isRebrand : false,
  preload   : 'none',
  width     : '100%'
};


/**
 * This is the Animoto wrapper for video.js Player
 */
export default class Player {
  /**
   * Constructor for the Player class.
   *
   * @method constructor
   *
   * @param  {String} playerElementId - The ID of the element on the page in which to instantiate the player
   * @param  {Object} videoOptions - All of the options necessary to instantiate the player (playlist, cover image, etc.)
   *
   */
  constructor(playerElementId, videoOptions = {}) {
    if (!Array.isArray(videoOptions.sources)) {
      throw new Error(`Expected videoOptions to include an array of sources but got: ${videoOptions.sources}`);
    }
    videoOptions.sources = appendTypeToSources(videoOptions.sources);

    const newOptions = convertOptions(videoOptions);
    const setupOptions = { ...defaultVideoOptions, ...newOptions };

    if (!setupOptions.aspectRatio) {
      setupOptions.fluid = true;
    }

    this.uuid = uuidV4();

    this.playerElementId = playerElementId;

    this.setupPlayer(setupOptions);

    // manually applies https://github.com/videojs/video.js/pull/7474/files to fix translation issue since upgrading videojs causes errors for our hls streaming
    const element = document.getElementById(playerElementId);
    element.setAttribute('translate', 'no');
  }

  /**
   * Instantiates a videojs instance on an element calls setup on that instance.
   * Can be called multiple times on a player instance
   *
   * @method setupPlayer
   * @private
   *
   * @param  {Object} setupOptions - All of the options necessary to instantiate the player (playlist, cover image, etc.)
   *
   */
  setupPlayer(setupOptions) {
    if (this.player) {
      this.destroy();
    }

    const { sources, poster, isRebrand } = setupOptions;

    delete setupOptions.sources;
    delete setupOptions.poster;

    this.disableRightClickMenu();

    this.player = videojs(this.playerElementId, setupOptions);
    this.player.addClass('video-js');
    if (isRebrand) {
      this.player.addClass('rebrand-player');
    }
    this.player.volume(0.9);

    // Need to set sources using .src due to some issues in videojs when passing it as an option for HLS
    this.setSources({ sources, poster });

    // If there is a CTA set on the video
    if (setupOptions.ctaSettings) {
      this.cta = new PlayerCTA(setupOptions.ctaSettings, this.playerElementId);
      this.player.on('play', () => { this.cta.hideCTA(); });
      this.player.on('pause', () => { this.cta.showCTA(); });
      this.player.on('complete', () => { this.cta.showCTA(); });
    }

    this.player.on('error', () => {
      const erroredSource = this.player.currentSource();
      const currentSources = this.player.currentSources();

      const newSources = currentSources.filter(source => source.src !== erroredSource.src);

      if (newSources.length) {
        this.setSources({
          sources : newSources
        });

        this.player.play();
      }
    });
  }

  // TODO: implement test for this
  /**
   * Disables the right click menu from showing up for our player
   *
   * @method disableRightClickMenu
   *
   */
  disableRightClickMenu() {
    const videoElement = document.getElementById(this.playerElementId);
    videoElement.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    }, false);
  }

  /**
   * Check if current playback file is HLS stream
   *
   * @method isHLS
   *
   * @returns {Boolean} Is current playback HLS stream
   *
   */
  isHLS() {
    return this.player.currentSource().src.indexOf('.m3u8') !== -1;
  }

  /**
   * Set the volume on the wrapped player instance
   *
   * @method setVolume
   *
   * @param  {Integer} volume      The volume level on a 0 - 100 scale
   *
   * @example
   *   playerInstanceVariable.setVolume(20);
   */
  setVolume(volume) {
    this.player.volume(volume);
  }

  setCurrentTime(newTime) {
    this.player.currentTime(newTime);
  }

  setMute(isMuted) {
    this.player.muted(isMuted);
  }

  getCurrentTime() {
    return this.player.currentTime();
  }

  getPlayerState() {
    return this.player.getState();
  }

  on(...args) {
    this.player.on(...args);
  }

  once(...args) {
    this.player.one(...args);
  }

  off(...args) {
    this.player.off(...args);
  }

  play() {
    this.player.play();
  }

  pause() {
    this.player.pause();
  }

  /**
   * Sets new sources and poster to an existing player instance
   *
   * @method setSources
   * @public
   *
   * @param {Array} sources - Sources to set
   * @param {String} poster - Poster to set
   *
   */
  setSources({ sources, poster }) {
    if (poster !== undefined) {
      this.poster = poster;
    }
    const sortedSources = sortSources(sources);
    this.player.src(appendTypeToSources(sortedSources));
    this.player.poster(poster);
    if (this.cta) {
      this.cta.hideCTA();
    }
  }

  /**
   * Sets up a one time event listener for the "seeked" event to pause
   * the player if it was paused before seeking
   *
   * @method maintainPauseAfterSeek
   * @private
   *
   * @param {String} playState The play state on seeking
   *
   * @example
   *   this.maintainPauseAfterSeek('paused');
   */
  maintainPauseAfterSeek(playState) {
    if (playState === 'paused') {
      this.player.one('seeked', () => {
        this.player.pause();
      });
    }
  }

  /**
   * Seeks player to the point it was at before loading new playlist
   *
   * @method keepPlayPositionAfterPlaylistLoad
   * @public
   *
   * @param {String} preLoadPlayState The play state before loading playlist
   *
   * @example
   *   this.keepPlayPositionAfterPlaylistLoad('paused');
   */
  keepPlayPositionAfterPlaylistLoad(preLoadPlayState) {
    const position = this.player.getPosition();

    this.player.one('playlistitem', () => {
      this.maintainPauseAfterSeek(preLoadPlayState);
      if (position > 0) {
        this.player.seek(position);
      }
    });
  }

  isLiveStream() {
    return this.player.duration() === Infinity;
  }

  /**
   * Removes JPlayer instance and CTA DOM
   *
   * @method destroy
   * @public
   *
   * @example
   *   playerInstance.destroy();
   */
  destroy() {
    if (this.cta) {
      this.cta.hideCTA();
    }
    this.player.dispose();
  }
}
